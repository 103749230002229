// Packages
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField, Typography } from '@mui/material';

// Relatives
import MaskedInput from '../../../../components/MaskedInput';

const OrganisationStep3 = () => {
  const { control } = useFormContext();

  return (
    <>
      <Grid item xs={12}>
        <Controller
          name="ein"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="EIN (Or Country Equivalente Tax Number)"
              variant="standard"
              InputProps={{
                inputComponent: MaskedInput,
                inputProps: {
                  name: field.name,
                  mask: /^[0-9a-zA-Z-]{0,10}$/
                }
              }}
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="bank_account_bank_name"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="Bank Name"
              variant="standard"
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="bank_account_account_number"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="Bank Account Number"
              variant="standard"
              InputProps={{
                inputComponent: MaskedInput,
                inputProps: {
                  name: field.name,
                  mask: /^[0-9a-zA-Z]{0,11}$/
                }
              }}
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="bank_account_routing_number"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="Bank Routing Number"
              variant="standard"
              InputProps={{
                inputComponent: MaskedInput,
                inputProps: {
                  name: field.name,
                  mask: /^[0-9a-zA-Z]{0,11}$/
                }
              }}
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="bank_account_holder_name"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="Bank Holder Name"
              variant="standard"
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="bank_account_swift_code"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="SWIFT Code"
              variant="standard"
              InputProps={{
                inputComponent: MaskedInput,
                inputProps: {
                  name: field.name,
                  mask: /^[0-9a-zA-Z]{0,11}$/
                }
              }}
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="bank_account_iban_number"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="IBAN"
              variant="standard"
              InputProps={{
                inputComponent: MaskedInput,
                inputProps: {
                  name: field.name,
                  mask: /^[0-9a-zA-Z]{0,25}$/
                }
              }}
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="bank_account_account_number"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="Bank Account Number"
              variant="standard"
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>
    </>
  );
};

OrganisationStep3.defaultProps = {};

OrganisationStep3.propTypes = {};

export default OrganisationStep3;
