// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField, Typography } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';

// Relatives
import AddressLookup from '../../../components/AddressLookup';

const FundraiserStep2 = props => {
  const { updateMode, cache } = props;
  const { control, setValue, getValues } = useFormContext();
  /* const [isAddressManual, setIsAddressManual] = useState(
    () => !getValues('full_address') && getValues('street_address')
  ); */

  /* const handleChange = fieldOnChange => address => {
    if (!address) {
      fieldOnChange('');

      return;
    }

    const { fullAddress, streetNumber, streetName, postalCode, stateName, locality, occupancyIdentifier, countryCode } =
      address;

    setValue('country', countryCode);
    setValue('street_address', `${streetNumber || ''} ${streetName || ''}`);
    setValue('street_address_2', occupancyIdentifier);
    setValue('city', locality);
    setValue('state', stateName);
    setValue('zip', postalCode);

    fieldOnChange(fullAddress);
  }; */

  // const handleChangeMode = mode => setIsAddressManual(mode);

  const handleBlurFromDate = value => {
    const toDate = getValues('date_to');
    if (!toDate) {
      setValue('date_to', moment(value, 'MM/DD/YYYY', true).add('1', 'hour').toDate());
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <AddressLookup cache={cache} />
      </Grid>
      <Typography color="primary" variant="h1" className="text-center mx-auto">
        Date & Time
      </Typography>
      <Grid item xs={12} className="d-flex">
        <Controller
          name="date_base"
          control={control}
          rules={{
            required: 'Date is required',
            validate: {
              dateInvalid: value => {
                const diff = moment(value, 'MM/DD/YYYY', true).diff(moment(new Date().setUTCHours(0, 0, 0, 0)));

                if (!updateMode && diff < 0) {
                  return 'This date is invalid';
                }

                return !moment(value, 'MM/DD/YYYY', true).isValid() ? 'This date is invalid' : true;
              }
            }
          }}
          render={({ field, fieldState }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                {...field}
                label="Date"
                minDate={new Date()}
                format="MM/dd/yyyy"
                clearable
                renderInput={props => (
                  <TextField
                    {...props}
                    variant="standard"
                    className="flex-grow-1"
                    helperText={
                      fieldState.error && (
                        <Typography variant="p" color="error">
                          {fieldState.error.message}
                        </Typography>
                      )
                    }
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
        <Controller
          name="date_from"
          control={control}
          rules={{
            required: 'From time is required',
            validate: {
              dateInvalid: value => {
                const diff = moment(value, 'MM/DD/YYYY', true).diff(moment(new Date().setUTCHours(0, 0, 0, 0)));

                if (!updateMode && diff < 0) {
                  return 'This date is invalid';
                }

                return !moment(value, 'MM/DD/YYYY', true).isValid() ? 'This date is invalid' : true;
              }
            }
          }}
          render={({ field, fieldState }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                {...field}
                label="From"
                minDate={new Date()}
                onAccept={handleBlurFromDate}
                format="MM/dd/yyyy"
                clearable
                ampm={false}
                renderInput={props => (
                  <TextField
                    {...props}
                    variant="standard"
                    className="flex-grow-1"
                    helperText={
                      fieldState.error && (
                        <Typography variant="p" color="error">
                          {fieldState.error.message}
                        </Typography>
                      )
                    }
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
        <Controller
          name="date_to"
          control={control}
          rules={{
            required: 'To time is required',
            validate: {
              dateInvalid: value => {
                const momentDate = moment(value, 'MM/DD/YYYY', true);
                const diff = momentDate.diff(moment(new Date().setUTCHours(0, 0, 0, 0)));
                const diffFrom = momentDate.diff(getValues('date_from'));

                return diff < 0 || diffFrom < 0 || !momentDate.isValid() ? 'This date is invalid' : true;
              }
            }
          }}
          render={({ field, fieldState }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                {...field}
                label="To"
                format="MM/dd/yyyy"
                clearable
                ampm={false}
                minDate={new Date()}
                invalidDateMessage="End date is required"
                renderInput={props => (
                  <TextField
                    {...props}
                    variant="standard"
                    className="flex-grow-1"
                    helperText={
                      fieldState.error && (
                        <Typography variant="p" color="error">
                          {fieldState.error.message}
                        </Typography>
                      )
                    }
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
    </>
  );
};

FundraiserStep2.defaultProps = {
  updateMode: false,
  cache: {}
};

FundraiserStep2.propTypes = {
  updateMode: PropTypes.bool,
  cache: PropTypes.object
};

export default FundraiserStep2;
