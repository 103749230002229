/* eslint-disable no-lonely-if */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Button, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { isEmpty, noop, debounce } from 'lodash';
import axios from 'axios';
import BackspaceIcon from '@mui/icons-material/Backspace';
import CachedIcon from '@mui/icons-material/Cached';
import { useNavigate } from 'react-router';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Skeleton from '@mui/material/Skeleton';

import http from '../../services/api/http';
import UserContext from '../../contexts/UserContext/UserContext';
import AppContext from '../../contexts/AppContext';

import logo from '../../styles/images/logo.png';

const emptyString = '';
const devMode = false;
const emptyLoop = [1, 2, 3, 4, 5, 6, 7, 8];

const JoinNGO = props => {
  // Base
  let cancelToken;
  const { data, required, renderComponent, onSubmit, temp } = props;
  const { getUser, logout, userNGOSelected } = useContext(UserContext);
  const user = getUser();
  const { token } = user;
  const { apiHost } = useContext(AppContext);
  const navigate = useNavigate();
  // Requests
  const [ngoName, setNgoName] = useState('');
  const [OpenModal, setOpenModal] = useState(false);
  const [OpenDialog, setOpenDialog] = useState(false);
  const [register, setRegister] = useState(false);
  const [ListRequests, setListRequest] = useState([]);
  const [enabledContinue, setEnabledContinue] = useState(false);
  const [NewRequestJoin, setNewRequestJoin] = useState([]);
  const [listngos, setListNgos] = useState([]);
  const [myListngos, setMyListNgos] = useState([]);
  const [expand, setExpand] = useState(false);

  const getNgoList = async (sort = 'id', find = '') => {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }

    cancelToken = axios.CancelToken.source();

    try {
      if (!isEmpty(find)) {
        const response = await http(apiHost, token.accessToken, token.tokenType).get(
          `/api/web/v2/non-profit-ngos?sort=id&${sort}=${find}`,
          {
            cancelToken: cancelToken.token
          }
        );
        setListNgos(response.data.output.data);
      } else {
        setListNgos([]);
      }
      // console.log(response.data.output.data, 'NGOS')
    } catch (error) {
      // console.log(error);
    }

    return;
  };

  const handleFindNGO = name => {
    getNgoList('name', name);
    handleCheckStatus();
    setExpand(true);
  };

  const debounceHandleFindNGO = useCallback(debounce(handleFindNGO, 600), []);

  const handleJoinNGO = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType).post(`/api/web/v2/joining-requests`, {
      type: NewRequestJoin.type,
      model_id: NewRequestJoin.ngoId
    });

    const { success } = response.data;

    if (success) {
      setOpenModal(true);

      setTimeout(() => {
        handleCheckStatus();
      }, 4000);
    }
  };

  const verifiedStatus = (id, approved, rejected) => {
    return ListRequests.some(item => {
      return item.model_id === id && item.is_approved === approved && item.is_rejected === rejected;
    });
  };

  const handleClearInput = () => {
    setListNgos([]);
    setNgoName(emptyString);
    data({ virtualArtistId: 0, virtualArtistUserId: 0, newNgoName: '', ngoId: 0, Settings: null });
  };

  const handleToggle = () => {
    setExpand(expand ? false : true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCreateNewJoinNGO = (type, ngoId) => {
    setNewRequestJoin({
      type,
      ngoId
    });

    setOpenDialog(true);
  };

  const handleCheckStatus = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType).get(`/api/web/v2/joining-requests`);

    const { success, output } = response.data;

    if (success) {
      const responseRules = output.data.some(item => item.is_approved === true);
      setListRequest(output.data);
      if (responseRules || !isEmpty(userNGOSelected)) {
        setEnabledContinue(true);

        if (temp) {
          onSubmit(true);
        }
      } else {
        setEnabledContinue(false);
      }
    }
  };

  const getNgoData = async (id, completed, approved) => {
    http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/non-profit-ngos/${id}`)
      .then(response => {
        const ngo = response.data.output;
        // RULES
        if ((!completed && !approved)) {
            myListngos.push(ngo);
            setMyListNgos([...new Set(myListngos)]);
        }
      });
  };

  const MySendedRequests = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType).get(
      `/api/web/v2/joining-requests?page_size=999`
    );
    const { success, output } = response.data;

    if (success) {
      const unique = [];
      output.data.forEach(async item => {
        if (!unique.includes(item.model_id)) {
          getNgoData(item.model_id, item.is_completed, item.is_approved);
          unique.push(item.model_id);
        }
      });
    }
  };

  const handleAgreeDialog = () => {
    handleJoinNGO().then(() => {
      handleCheckStatus();
    });
    setOpenDialog(false);
  };

  const showJoinBtn = status => (
    <div className='JoinNGO-status'>
      {status === false && <span className="JoinNGO-end">Join</span>}
      {status === true && <span className="JoinNGO-end bg-warning text-dark">Request Sent</span>}
    </div>
  );

  const handleNone = () => { console.log('') }

  useEffect(() => {
    if (!isEmpty(ngoName)) {
      getNgoList();
      setMyListNgos([]);
    }

    if (isEmpty(myListngos)) {
      MySendedRequests();
    }
  }, [ngoName]);

  useEffect(() => {
    handleCheckStatus();
    if (isEmpty(myListngos)) {
      setNgoName('first load');
      setNgoName('');
    }
  }, []);

  return (
    <div onClick={handleToggle} key="JoinNGO" className="JoinNGO-window mt-5">
      {!register && (
        <div className="JoinNGO">
          <div style={{ position: 'relative' }}>
            <input
              onChange={input => {
                debounceHandleFindNGO(input.target.value);
                setNgoName(input.target.value);
              }}
              key="JoinNGO_text-input"
              className="form-select"
              type="text"
              value={ngoName}
              placeholder="Please type the name of the association"
              required={required}
            />
            <a
              onClick={() => {
                handleClearInput();
              }}
              className={`JoinNGO_btn-clear ${ngoName.length > 0 ? 'show' : 'visually-hidden'}`}
            >
              <BackspaceIcon />
            </a>
            {devMode && (
              <Button
                onClick={() => {
                  handleCheckStatus();
                }}
              >
                Check status <CachedIcon />
              </Button>
            )}
            {devMode && enabledContinue && (
              <Link
                onClick={() => {
                  navigate('/campaigns');
                }}
                className="btn btn-link"
              >
                Go to Dashboard
              </Link>
            )}
          </div>
          <hr />
          <div
            key="JoinNGO_list"
            style={{ maxHeight: '350px', minHeight: '230px' }}
            className="JoinNGO__list overflow-auto"
          >
            {listngos.length > 0 &&
              listngos.map(NGO => (
                <Button
                  className="w-100 justify-content-start"
                  key={`JoinNGO_item_${NGO.virtual_artist_id}-my`}
                  onClick={() => {
                    if (verifiedStatus(NGO.id, true, false)) {
                      handleNone();
                    } else {
                      if (verifiedStatus(NGO.id, false, false) === false) {
                        handleCreateNewJoinNGO('ngo', NGO.id);
                      }
                    }
                  }}
                >
                  <img
                    onError={event => {
                      event.target.src = logo;
                    }}
                    src={`${apiHost}${NGO._misc.avatar.small_image}`}
                    style={{ width: '46px' }}
                    className="JoinNGO-image img-thumbnail me-2"
                    alt="..."
                  />
                  <span className="JoinNGO-name text-dark">
                    {NGO.name}
                    <br />
                    <small>{NGO.virtual_artist_handle}</small>
                  </span>
                  {showJoinBtn(verifiedStatus(NGO.id, false, false))}
                </Button>
              ))}
            {listngos.length <= 0 && !isEmpty(ngoName) && <Button className="w-100">No results found</Button>}
            {listngos.length <= 0 && isEmpty(ngoName) && (
              <Button className="w-100">Please type the name of the association above</Button>
            )}
            {myListngos.length > 0 &&
              isEmpty(ngoName) &&
              myListngos.map(NGO => (
                <Button
                  className="w-100 justify-content-start"
                  key={`JoinNGO_item_${NGO.virtual_artist_id}`}
                  onClick={() => {
                    handleNone();
                  }}
                >
                  <img
                    onError={event => {
                      event.target.src = logo;
                    }}
                    src={`${apiHost}${NGO._misc.avatar.small_image}`}
                    style={{ width: '46px' }}
                    className="JoinNGO-image img-thumbnail me-2"
                    alt="..."
                  />
                  <span className="JoinNGO-name text-dark">
                    {NGO.name}
                    <br />
                    <small>{NGO.virtual_artist_handle}</small>
                  </span>
                  {verifiedStatus(NGO.id, true, false) ? (
                    <span className="JoinNGO-end bg-success text-white">Already Joined</span>
                  ) : (
                    showJoinBtn(verifiedStatus(NGO.id, false, false))
                  )}
                </Button>
              ))}
            {myListngos.length <= 0 &&
              isEmpty(ngoName) &&
              emptyLoop.map(item => (
                <Button
                  className="w-100 justify-content-start"
                  key={`JoinNGO_item_0${item}-my`}
                  onClick={() => {
                    handleNone();
                  }}
                >
                  <Skeleton style={{ background: '#918f8f' }} animation="wave" width={46} height={46} />
                  <span className="JoinNGO-name text-dark">
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="80%"
                      style={{ background: '#918f8f', position: 'absolute', marginTop: '1px', marginLeft: '5px' }} />
                    <br />
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="70%"
                      style={{ background: '#918f8f', position: 'absolute', marginTop: '1px', marginLeft: '5px' }} />
                  </span>
                </Button>
              ))
            }
          </div>
          <div className="joinNGO_footer mt-5">
            <p>
              Association not listed?{' '}
              <Link
                className="btn btn-link"
                onClick={() => {
                  setRegister(true);
                }}
              >
                Create one here.
              </Link>
            </p>
            {!temp && (
              <p className="text-center">
                <Button
                  onClick={() => {
                    navigate('/campaigns');
                  }}
                >
                  Cancel
                </Button>
              </p>
            )}
          </div>
        </div>
      )}
      {!isEmpty(renderComponent) && register && renderComponent}
      <Modal
        isOpen={OpenModal}
        toggle={() => {
          setOpenModal(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            setOpenModal(false);
          }}
        >
          Alert
        </ModalHeader>
        <ModalBody className="text-center">
          <p>
            {`We've`} received your manage request.
            <br />
            It may take up 48 hours to get reviewed. if this an Emergency please contact us to{' '}
            <a href="mailto:insider@picklejar.com">insider@picklejar.com</a>
          </p>
          <p>
            <Link
              onClick={() => {
                setOpenModal(false);
              }}
              className="btn btn-link"
            >
              Stay and join or register another
            </Link>{' '}
            or{' '}
            <Link
              className="btn btn-link"
              onClick={() => {
                navigate('/');
                logout();
              }}
            >
              Logout
            </Link>
          </p>
        </ModalBody>
      </Modal>
      <Dialog
        open={OpenDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            are you sure you want to join to this association?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>No</Button>
          <Button onClick={handleAgreeDialog} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

JoinNGO.defaultProps = {
  data: noop,
  onSubmit: noop,
  required: false,
  renderComponent: null,
  temp: true
};

JoinNGO.propTypes = {
  data: PropTypes.func,
  onSubmit: PropTypes.func,
  required: PropTypes.bool,
  renderComponent: PropTypes.element,
  temp: PropTypes.bool
};

export default JoinNGO;
