// Packages
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { Typography } from '@mui/material';

// Relatives
import VerifySms from './steps/VerifySms';
import LoginPhone from './steps/LoginPhone';
import UserContext from '../../contexts/UserContext/UserContext';
import UserProfile from './steps/UserProfile';
import Organisation from './steps/Organisation';
import JoinNGO from '../../components/joinNGO/JoinNGO';
import AppContext from '../../contexts/AppContext';
import http from '../../services/api/http';

const Login = props => {
  const { isRegister } = props;
  const { setUser, getUser, setUserParams, setUserStatus, logout } = useContext(UserContext);
  const { apiHost } = useContext(AppContext);
  const [mobilePhone, setMobilePhone] = useState('');
  const [phase, setPhase] = useState(() => {
    const user = getUser();
    if (!user) {
      return 'phase1';
    }

    const { status } = user;
    if (status === 'inactive') {
      return 'phase3';
    }

    return 'phase4';
  });
  const [confirmationToken, setConfirmationToken] = useState('');
  const navigate = useNavigate();

  const loginPhase1 = async data => {
    if (!data) {
      return;
    }

    const { mobilePhone, confirmationToken } = data;
    setMobilePhone(mobilePhone);
    setConfirmationToken(confirmationToken);
    setPhase('phase2');
  };

  const loginPhase2 = async data => {
    if (!data) {
      return;
    }

    const { id, token, userName, profile } = data;
    if (data.status === 'inactive') {
      setUser({ id, token, status: 'inactive', params: { userName, profile } });
      setPhase('phase3');
      navigate('/register');
    } else if (isRegister) {
      setUser({ id, token, status: 'new-ngo', params: { userName, profile } });
      setPhase('phase4');
    } else {
      const response = await http(apiHost, token.accessToken, token.tokenType)
        .get(`/api/web/v2/users/me`)
        .catch(() => {});

      const { success, output } = response.data;

      const ngos = get(output, '_related.managed_non_profit_ngos', []);

      if (success) {
          if (ngos.length > 0) {
            setUser({ id, token, status: 'active', params: { userName, profile } });
            navigate('/campaigns');
          } else {
            setUser({ id, token, status: 'new-ngo', params: { userName, profile } });
            setPhase('phase4');
          }
      } else {
        logout();
        navigate('/');
      }
    }
  };

  const loginPhase3 = data => {
    if (!data) {
      return;
    }

    setUserParams({ userName: data.handle });
    setUserStatus('new-ngo');
    setPhase('phase4');
  };

  const loginPhase4 = data => {
    if (!data) {
      return;
    }

    setUserStatus('active');
    navigate('/campaigns');
  };

  const reset = () => {
    setPhase('phase1');
    setConfirmationToken('');
    setMobilePhone('');
  };

  const loginCancel = () => {
    logout();
    navigate('/');
  };

  return (
    <div className="Login" key="login">
      <img src="./images/back.jpg" alt="register background" className="background" />
      {phase === 'phase1' && <LoginPhone onSubmit={loginPhase1} />}
      {phase === 'phase2' && (
        <VerifySms
          mobilePhone={mobilePhone}
          onSubmit={loginPhase2}
          onReset={reset}
          confirmationToken={confirmationToken}
        />
      )}
      {phase === 'phase3' && <UserProfile onSubmit={loginPhase3} />}
      {phase === 'phase4' && <JoinNGO onSubmit={loginPhase4} renderComponent={<Organisation onSubmit={loginPhase4} />} />}
      {(phase === 'phase3' || phase === 'phase4') && (
        <div className="d-flex mt-4">
            <Typography type='button' component="p" color="primary" className='m-2'
                onClick={loginCancel}>
                <span>Log out</span>
            </Typography>
        </div>
      )}
    </div>
  );
};

Login.defaultProps = {
  isRegister: false
};

Login.propTypes = {
  isRegister: PropTypes.bool
};

export default Login;
