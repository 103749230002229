// Packages
import React, { useCallback, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AsyncSelect from 'react-select/async';
import AddIcon from '@mui/icons-material/Add';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

// Relatives
import Participant from './Participant';
import http from '../../../services/api/http';
import UserContext from '../../../contexts/UserContext';
import AppContext from '../../../contexts/AppContext';

const ParticipantList = props => {
  const { eventId, items, className } = props;
  const [openModal, setOpenModal] = useState(false);
  const [valueSelected, setValueSelected] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);

  const handleCloseModal = e => {
    e.stopPropagation();
    e.preventDefault();
    setOpenModal(false);
  };

  const handleClickModal = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleClickAdd = e => {
    e.stopPropagation();
    e.preventDefault();
    setOpenModal(true);
  };

  const handleChange = selectedOption => {
    if (!selectedOption) {
      setValueSelected(null);

      return;
    }

    setValueSelected(selectedOption);
  };

  const handleClickSubmit = async () => {
    const { value, optionals } = valueSelected;
    const postParams = { event_id: eventId, split_config_percentage: 0 };
    if (optionals.type === 'artist') {
      postParams.artist_id = value;
    } else {
      postParams.band_id = value;
    }

    setLoading(true);
    const response = await http(apiHost, token.accessToken, token.tokenType).post(
      '/api/web/v1/events/add-band-or-artist-to-event',
      postParams
    );

    setLoading(false);
    const { success, data } = get(response, 'data');
    if (!success || !data || !data.event) {
      setError('This Sponsor Stage Name already exist');

      return;
    }

    setError(null);
    setOpenModal(false);
    window.location.reload();
  };

  const promiseOptions = (inputValue, callback) => {
    if (!inputValue) {
      callback([]);

      return;
    }

    setLoading(true);
    // Artists
    const promiseArtists = http(apiHost, token.accessToken, token.tokenType).get(
      `api/web/v1/search-handle/search?handle=@${inputValue}&exclude_business=1&exclude_bands=1&exclude_child_labels=1&exclude_users=1&exclude_labels=1`
    );

    // Bands
    const promiseBands = http(apiHost, token.accessToken, token.tokenType).get(
      `api/web/v1/search-handle/search?handle=@${inputValue}&exclude_artists=1&exclude_bands=1&exclude_child_labels=1&exclude_users=1&exclude_labels=1`
    );

    Promise.allSettled([promiseArtists, promiseBands]).then(([responseArtists, responseBands]) => {
      setLoading(false);
      if (!responseArtists.status === 'fulfilled') {
        callback([]);

        return;
      }

      const { success: successArtists, data: dataArtists } = get(responseArtists, 'value.data', {
        success: false,
        data: []
      });
      if (!successArtists) {
        callback([]);

        return;
      }

      if (!responseBands.status === 'fulfilled') {
        callback([]);

        return;
      }
      const { success: successBands, data: dataBands } = get(responseBands, 'value.data', { success: false, data: [] });
      if (!successBands) {
        callback([]);

        return;
      }

      const result = [
        {
          label: 'Artists',
          options: dataArtists.slice(0, 5).reduce(
            (acum, artist) => [
              ...acum,
              {
                value: get(artist, 'artist.id'),
                label: get(artist, 'artist.handle'),
                optionals: { ...artist, type: 'artist' }
              }
            ],
            []
          )
        },
        {
          label: 'Bands',
          options: dataBands.slice(0, 5).reduce(
            (acum, band) => [
              ...acum,
              {
                value: get(band, 'band.id'),
                label: get(band, 'band.handle'),
                optionals: { ...band, type: 'band' }
              }
            ],
            []
          )
        }
      ];

      callback(result);
    });
  };

  const promiseOptionsDebounced = useRef(debounce(promiseOptions, 150));

  const formatCreateLabelMemo = useCallback(text => text, []);

  return (
    <div className={classNames('participant-list', { [className]: className })}>
      <div className="list__container">
        {items.slice(0, 5).map((item, i) => (
          <Participant key={i} {...item} />
        ))}
      </div>
      <button type="button" className="add-participant" title="Add Participant" onClick={handleClickAdd}>
      <AddIcon />
      </button>
      <Modal
        className={classNames('participant-modal', { [className]: className })}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={handleClickModal}
      >
        <Container maxWidth="sm">
          <div className="modal-header">
            <Typography id="modal-modal-title" component="h6" className="title">
              Join forces to raise more money! Invite another member to help with this Campaign
            </Typography>
            <CloseIcon className="close" onClick={handleCloseModal} />
          </div>
          <div className="modal-body">
            <div className="participant-search-container">
              <AsyncSelect
                className="address-lookup__search-input input--autocomplete"
                classNamePrefix="react-select"
                // value={valueMemo}
                onChange={handleChange}
                // placeholder={placeholder}
                isClearable
                isSearchable
                loadOptions={promiseOptionsDebounced.current}
                // menuIsOpen
                // defaultOptions={[{value:'abc',label:'abc'},{value:'abc2',label:'abc2'},{value:'abc3',label:'abc3'}]}
                // readOnly={readOnly}
                defaultOptions
                components={{ DropdownIndicator: null }}
                // allowCreateWhileLoading
                formatCreateLabel={formatCreateLabelMemo}
              />
              <Typography variant="p" color="error">
                {error}
              </Typography>
              <button className="btn btn-primary" type="button" disabled={loading} onClick={handleClickSubmit}>
                Add Sponsor Stage Name
              </button>
            </div>
          </div>
        </Container>
      </Modal>
    </div>
  );
};

ParticipantList.defaultProps = {
  className: '',
  items: [],
  eventId: 0
};

ParticipantList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default ParticipantList;
