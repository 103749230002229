// Packages
import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormHelperText, Grid, TextareaAutosize, TextField, Typography } from '@mui/material';

// Relatives
import UserContext from '../../../contexts/UserContext';

const FundraiserStep1 = () => {
  const { control } = useFormContext();
  const { userNGOSelected } = useContext(UserContext);

  return (
    <>
      <Grid item xs={12}>
        <Controller
          name="ngoname"
          render={() => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <div className="d-flex flex-row bd-highlight">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Organization Name
                </label>
              </div>
              <TextField
                variant="standard"
                defaultValue={userNGOSelected.name}
                InputProps={{
                  readOnly: true
                }}
              />
            </div>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="name"
          control={control}
          rules={{ required: 'Name is required' }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="What do you want to call your fundraiser?"
              variant="standard"
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="message"
          control={control}
          rules={{ required: 'Message is required' }}
          render={({ field, fieldState }) => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                Message
              </label>
              <TextareaAutosize {...field} maxLength={255} minRows={3} maxRows={6} label="Mission" variant="standard" />
              <FormHelperText>
                {fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </div>
          )}
        />
      </Grid>
    </>
  );
};

FundraiserStep1.defaultProps = {};

FundraiserStep1.propTypes = {};

export default FundraiserStep1;
