// Packages
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Container, Typography, CircularProgress, Stepper, Step, StepButton, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { get, noop, pick } from 'lodash';

// Relatives
import http from '../../../../services/api/http';
import AppContext from '../../../../contexts/AppContext';
import UserContext from '../../../../contexts/UserContext/UserContext';
import OrganisationStep1 from './OrganisationStep1';
import OrganisationStep2 from './OrganisationStep2';
import OrganisationStep3 from './OrganisationStep3';
import OrganisationStep4 from './OrganisationStep4';

const Organisation = props => {
  const { onSubmit, updateMode, financialUpdate, id, onCancel, temp } = props;
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(updateMode);
  const [activeStep, setActiveStep] = useState(0);
  const [cache, setCache] = useState({});
  const [completed, setCompleted] = useState({});

  let organisationFormSettings;

  if(!financialUpdate) {
    organisationFormSettings = {
      defaultValues: {
        // step 1
        name: '',
        virtual_artist_handle: '',
        nickname: '',
        about: '',
        mission: '',
        // step 2
        formatted_address: '',
        country: '',
        street_address: '',
        street_address_2: '',
        city: '',
        state: '',
        zip: '',
        lat: '0',
        lon: '0',
        main_phone: '+1',
        size: '',
        scope: '',
        non_profit_ngo_subtype_id: '',
        // step 4
        banner_id: '',
        media_id: ''
      }
    }

  } else {

    organisationFormSettings = {
      defaultValues: {
        ein: '',
        bank_account_bank_name: '',
        bank_account_holder_name: '',
        bank_account_account_number: '',
        bank_account_routing_number: '',
        bank_account_swift_code: '',
        bank_account_iban_number: ''
      }
    }

  }

  const organisationForm = useForm(organisationFormSettings);

  let steps;

  if(updateMode){

    steps = [
      {
        label: 'Let Us Know More About You',
        content: <OrganisationStep1 />
      },
      {
        label: 'Association Details',
        content: <OrganisationStep2 setLoading={setLoading} />
      },
      {
        label: 'Profile and Banner',
        content: <OrganisationStep4 setStepLoading={setLoading} cache={cache} setCache={setCache} />
      }
    ];

  } else if (financialUpdate) {
    steps = [
      {
        label: "Let's Get You Your  Funds!",
        content: <OrganisationStep3 />
      }
    ];
  } else{

    steps = [
      {
        label: 'Let Us Know More About You',
        content: <OrganisationStep1 />
      },
      {
        label: 'Association Details',
        content: <OrganisationStep2 setLoading={setLoading} />
      },
      {
        label: "Let's Get You Your  Funds!",
        content: <OrganisationStep3 />
      },
      {
        label: 'Profile and Banner',
        content: <OrganisationStep4 setStepLoading={setLoading} cache={cache} setCache={setCache} />
      }
    ];
  }

  const handleStep = step => async () => {
    if (await organisationForm.trigger()) {
      setActiveStep(step);
    }
  };

  const handleBack = () => setActiveStep(state => state - 1);

  const handleNext = async e => {
    e.stopPropagation();
    e.preventDefault();
    if (await organisationForm.trigger()) {
      setActiveStep(state => state + 1);
    }
  };

  const stepperSubmit = async data => {
    if (activeStep !== steps.length - 1 && (await organisationForm.trigger())) {
      setCompleted(state => ({ ...state, [activeStep]: true }));
      setActiveStep(activeStep + 1);
    } else {
      handleSubmit(data);
    }
  };

  const handleSubmit = async formData => {
    if (!formData || !token) {
      return;
    }

    let newFormData;

    if(formData.address_manual_mode === true){
      newFormData = {
          ...formData,
          lat: null,
          lon: null,
          skip_address_lookup: 1,
          skip_timezone_lookup: 1
        };
    }else{
      newFormData = {
        ...formData,
        skip_address_lookup: 1,
        skip_timezone_lookup: 1
      };
    }

    let response;
    if (updateMode || financialUpdate) {
      response = await http(apiHost, token.accessToken, token.tokenType)
        .patch(`/api/web/v2/non-profit-ngos/${id}`, newFormData)
        .catch(() => {});
    } else {
      response = await http(apiHost, token.accessToken, token.tokenType)
        .post('/api/web/v2/non-profit-ngos', newFormData)
        .catch(() => {});
    }

    setLoading(false);
    if (!response || !response.data) {
      return;
    }

    const { success, output } = get(response, 'data', {});
    if (!success && output) {
      let pageToBack = 0;
      Object.keys(output).forEach(field => {
        organisationForm.setError(field, { type: 'required', message: get(output, `${field}.0`) });
        if (['name', 'virtual_artist_handle', 'nickname', 'about', 'mission'].includes(field)) {
          pageToBack = 0;
        }/* else if (
          [
            'ein',
            'bank_account_bank_name',
            'bank_account_holder_name',
            'bank_account_swift_code',
            'bank_account_iban_number'
          ].includes(field)
        ) {
          pageToBack = 2;
        }  */
      });
      setActiveStep(pageToBack);

      return;
    }

    onSubmit(newFormData);
  };

  const loadModel = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/non-profit-ngos/${id}`)
      .catch(() => {});

    const { success, output, error } = get(response, 'data', {});
    if (!success) {
      console.warn(error);
      setLoading(false);

      return;
    }

    if(financialUpdate){
      organisationForm.reset({
        ...pick(output, [
          'ein',
          'bank_account_bank_name',
          'bank_account_holder_name',
          'bank_account_swift_code',
          'bank_account_iban_number',
          "bank_account_account_number",
          "bank_account_routing_number"
        ])
      });
    }else{
      organisationForm.reset({
        ...pick(output, [
          // step 1
          'name',
          'virtual_artist_handle',
          'nickname',
          'about',
          'mission',
          // step 2
          'formatted_address',
          'country',
          'street_address',
          'street_address_2',
          'city',
          'state',
          'zip',
          'lat',
          'lon',
          'main_phone',
          'size',
          'scope',
          'non_profit_ngo_subtype_id',
          // step 4
          'banner_id',
          'media_id'
        ])
      });

      setCache({
        bannerImage: get(output, '_misc.banner.large_image'),
        avatarImage: get(output, '_misc.avatar.large_image')
      });

    }

    setLoading(false);
  };

  useEffect(() => {
    if (updateMode && id || financialUpdate && id) {
      loadModel();
    }
  }, []);

  return (
    <div className="organisation campaign-create">
      <div className={loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </div>
      <Container  maxWidth="md">
        <Box className='d-flex flex-column align-items-stretch' sx={{ width: '100%' }}>
          <Stepper nonLinear activeStep={activeStep} className={`${financialUpdate ? 'visually-hidden' : 'py-4'}`}>
            {steps.map(({ label }, index) => (
              <Step key={label} completed={completed[index]} className="hide-sm-label">
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {/* {label} */}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            <FormProvider {...organisationForm}>
              <Box component="form" onSubmit={organisationForm.handleSubmit(stepperSubmit)} autoComplete="off">
                <Grid className='flex-column' container>
                  <Typography color="primary" variant="h1" className="text-center">
                    {steps[activeStep].label}
                  </Typography>
                  {steps[activeStep].content}
                </Grid>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, marginTop: '15px' }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    className="btn-forms"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {/* show when update mode is actived */}
                  {updateMode || !temp && (
                    <Button type="button" variant="outlined" className="btn-forms me-4" onClick={onCancel}>
                      Cancel
                    </Button>
                  )}
                  {/* show when finanancial mode is actived */}
                  {financialUpdate && (
                    <Button type="button" variant="outlined" className="btn-forms me-4" onClick={onCancel}>
                      Cancel
                    </Button>
                  )}
                  {activeStep + 1 === steps.length ? (
                    <Button type="submit" variant="outlined" className="btn-forms">
                      Submit
                    </Button>
                  ) : (
                    <Button
                      id="nextBtn"
                      sx={{ mr: 1 }}
                      color="primary"
                      type="button"
                      onClick={handleNext}
                      variant="outlined"
                      className="btn-forms"
                    >
                      Next
                    </Button>
                  )}
                </Box>
              </Box>
            </FormProvider>
          </div>
        </Box>
      </Container>
    </div>
  );
};

Organisation.defaultProps = {
  onSubmit: noop,
  onCancel: noop,
  id: null,
  updateMode: false,
  financialUpdate: false,
  temp: true
};

Organisation.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
  updateMode: PropTypes.bool,
  financialUpdate: PropTypes.bool,
  temp: PropTypes.bool
};

export default Organisation;
