import { useContext } from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil } from 'lodash';

// Relatives
import UserContext from '../../contexts/UserContext';


const LoaderImages = props => {
  const {
    defaultLoad,
    paramName,
    paramId,
    external,
    extResource
  } = props;
  const { userNGOSelected } = useContext(UserContext);
  const imageFromSession = get(userNGOSelected, paramName);
  const idImageFromSession = get(userNGOSelected, paramId);

  if(external){

      const imageFromCache = external.image;
      const idImageFromCache = external.id;

      return extResource({
        image: imageFromCache,
        id: idImageFromCache
      });
  }

  if(!external && !isNil(imageFromSession) && !isNil(idImageFromSession)){
     return extResource({
       image: imageFromSession,
       id: idImageFromSession
     });
  }


  return extResource({
    image: defaultLoad,
    id: null
  });

}


LoaderImages.defaultProps = {
  defaultLoad: null,
  paramName: 'none',
  paramId: 'none',
  external: false,
  extResource: noop
}

LoaderImages.propTypes = {
  defaultLoad: PropTypes.string,
  paramName: PropTypes.string,
  paramId: PropTypes.string,
  external: PropTypes.bool,
  extResource: PropTypes.func
}

export default LoaderImages;