// Packages
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { noop, get } from 'lodash';
import { IconButton, Menu, MenuItem /* , Badge */ } from '@mui/material';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import { /* AccountCircle, */ Logout } from '@mui/icons-material';
// import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { useNavigate } from 'react-router-dom';

// Relatives
import UserContext from '../../../contexts/UserContext';

const ProfileMenu = props => {
  const { onClose, isMobile, parentRef, isOpen, onSelectNGO, ngos, specialMenu } = props;
  const { logout, userNGOSelected } = useContext(UserContext);
  const navigate = useNavigate();

  const isAdmin = () => {
    const AdminPermitions = get(userNGOSelected, '_misc.can_admin');
    return AdminPermitions ? 'admin-only' : 'visually-hidden';
  }

  const handleClickNGO = id => () => onSelectNGO(id);

  const handleClickUpdateNGO = () => {
    navigate('/ngo/update');
  };

  const handleClickJoinNGO = () => {
    navigate('/join');
  };

  const handleClickNotesNGO = () => {
    navigate('/notes');
  };

  const handleClickUpdateNGOFinance = () => {
    navigate('/ngo/financial/update');
  }

  const handleClickUpdateUserProfile = () => {
    navigate('/user/profile/update');
  };

  const MobileMenu = [
    // <MenuItem onClick={onClose} key="1">
    //   <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
    //     <Badge badgeContent={17} color="error">
    //       <NotificationsIcon />
    //     </Badge>
    //   </IconButton>
    //   <span>Notifications</span>
    // </MenuItem>,
    // <MenuItem onClick={onClose} key="2">
    //   <IconButton size="large" aria-label="show 4 new mails" color="inherit">
    //     <Badge badgeContent={4} color="error">
    //       <AccountCircle />
    //     </Badge>
    //   </IconButton>
    //   <span>Messages</span>
    // </MenuItem>,
    // <MenuItem onClick={onClose} key="3">
    //   <IconButton
    //     size="large"
    //     aria-label="account of current user"
    //     aria-controls="primary-search-account-menu"
    //     aria-haspopup="true"
    //     color="inherit"
    //   >
    //     <AccountCircle />
    //   </IconButton>
    //   <span>Profile</span>
    // </MenuItem>,
    <div key="overflow-menu" className={specialMenu ? 'overflow-menu' : 'show'}>
    {
      [...ngos].map(ngo => (
        <MenuItem onClick={handleClickNGO(ngo.id)} key={ngo.virtual_artist_handle}>
          <IconButton size="large" color="inherit">
            <SwapHorizIcon />
          </IconButton>
          <span className="handle-label">{ngo.virtual_artist_handle}</span>
        </MenuItem>
      ))
      }
      <MenuItem onClick={handleClickNotesNGO} key="8">
    <IconButton size="large" color="inherit">
      <StickyNote2Icon />
    </IconButton>
    <div className="update-ngo-list-item">
      <span>Manage Notes</span>
    </div>
  </MenuItem>
      <MenuItem onClick={handleClickJoinNGO} key="8">
    <IconButton size="large" color="inherit">
      <AddCircleIcon />
    </IconButton>
    <div className="update-ngo-list-item">
      <span>Add new association</span>
    </div>
  </MenuItem>
    <MenuItem className={isAdmin()} onClick={handleClickUpdateNGOFinance} key="6">
    <IconButton size="large" color="inherit">
      <EditIcon />
    </IconButton>
    <div className="update-ngo-list-item">
      <span>Association Financial Details</span>
    </div>
  </MenuItem>
    <MenuItem className={isAdmin()} onClick={handleClickUpdateNGO} key="4">
      <IconButton size="large" color="inherit">
        <EditIcon />
      </IconButton>
      <div className="update-ngo-list-item">
        <span>Update Association</span>
        {/* <span>({get(props.ngoSelected, 'virtual_artist_handle')}qweqweqweqweqweqweqwe)</span> */}
      </div>
    </MenuItem>
    <MenuItem onClick={handleClickUpdateUserProfile} key="5">
      <IconButton size="large" color="inherit">
        <EditIcon />
      </IconButton>
      <div className="update-ngo-list-item">
        <span>Update Member</span>
      </div>
    </MenuItem>
    <MenuItem onClick={logout} key="7">
      <IconButton size="large" color="inherit">
        <Logout />
      </IconButton>
      <span>Logout</span>
    </MenuItem>
    </div>
  ];

  if (isMobile) {
    return MobileMenu;
  }

  return (
    <Menu
      className="layout-header-menu"
      anchorEl={parentRef.current}
      id="primary-search-account-menu-mobile"
      keepMounted
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <li className="menu-header text-center">
        <strong>User Options</strong>
      </li>
      {MobileMenu}
    </Menu>
  );
};

ProfileMenu.defaultProps = {
  parentRef: null,
  ngos: [],
  ngoSelected: null,
  isMobile: false,
  isOpen: false,
  onOpen: noop,
  onClose: noop,
  specialMenu: false,
  onSelectNGO: noop
};

ProfileMenu.propTypes = {
  parentRef: PropTypes.object,
  ngos: PropTypes.array,
  ngoSelected: PropTypes.object,
  isMobile: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  specialMenu: PropTypes.bool,
  onSelectNGO: PropTypes.func
};

export default ProfileMenu;
