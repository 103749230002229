// Packages
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { noop, isNil, isEmpty, get } from 'lodash';
import momentTz from 'moment-timezone';
import axios from 'axios';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField, Typography, FormHelperText } from '@mui/material';
// import { Alert } from 'reactstrap';

//  Relatives
import AddressLookupApi from "./AddressLookupApi";
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import http from '../../services/api/http';


const AddressLookup = props => {

  const { AddressData, cache } = props;
  let cancelToken;
  const { control, setValue, getValues } = useFormContext();
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const [lockReset, setLockReset] = useState(false);
  /* const [isAddressManual, setIsAddressManual] = useState(
    () => !getValues('full_address') && getValues('street_address') || getValues('address_manual_mode')
  ); */
  const [isAddressManual, setIsAddressManual] = useState(
    () => !isNil(cache.lat) && isNil(cache.lon) && !isEmpty(cache.lat) && !isEmpty(cache.lon) || getValues('address_manual_mode')
  );

  const handleChangeMode = mode => {
    setIsAddressManual(mode);
    if(!mode){
      handleReset();
    }
  }

  const handleScrollUp = () => {
    window.scrollTo(0, 150);
  }

  const handleReComfirmingAddress = async addressInput => {
    const timeZoneMoment = momentTz.tz.guess();
    try  {

      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.")
      }

      cancelToken = axios.CancelToken.source();

      const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/geocode/default/query?q=${addressInput}`, {
        cancelToken: cancelToken.token
      })
      .catch(() => {});

      const { success, output } = get(response, 'data', { success: false, data: [] });
      if (!success) {
        console.log(success);

        return;
      }

      const address = get(output, '0');

      const { streetNumber, streetName, postalCode, stateName, 
        city, occupancyIdentifier, countryCode, timezone, coordinates } = address;

    AddressData(address);
    
    
    setValue('country', countryCode);

    if((!isEmpty(streetName))){
      setValue('street_address', `${streetNumber || ''} ${streetName || ''}`);
    }else{
      setValue('street_address', null);
      setLockReset(true);
      document.getElementById('manualModeBtn').click();
      handleScrollUp();
      setTimeout(() => {
        document.getElementById('nextBtn').click();
      }, 500);
    }

    if((!isEmpty(city))){
      setValue('city', city);
    }else{
      setValue('city', null);
      setLockReset(true);
      document.getElementById('manualModeBtn').click();
      handleScrollUp();
      setTimeout(() => {
        document.getElementById('nextBtn').click();
      }, 500);
    }

    if((!isEmpty(stateName))){
      setValue('state', stateName);
    }else{
      setValue('state', null);
      setLockReset(true);
      document.getElementById('manualModeBtn').click();
      handleScrollUp();
      setTimeout(() => {
        document.getElementById('nextBtn').click();
      }, 500);
    }

    if((!isEmpty(postalCode))){
      setValue('zip', postalCode);
    }else{
      setValue('zip', null);
      setLockReset(true);
      document.getElementById('manualModeBtn').click();
      handleScrollUp();
      setTimeout(() => {
        document.getElementById('nextBtn').click();
      }, 500);
    }

    setValue('street_address_2', occupancyIdentifier);
    setValue('city', city);
    setValue('state', stateName);
    setValue('zip', postalCode);
    setValue('timezone', !isEmpty(timezone) ? timezone : timeZoneMoment);
    setValue('lat', coordinates.latitude);
    setValue('lon', coordinates.longitude);

    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = fieldOnChange => address => {
    if (!address) {
      fieldOnChange('');
      handleReset();

      return;
    }

    const { fullAddress } =
      address;

    AddressData(address);
  
    fieldOnChange(fullAddress);

    handleReComfirmingAddress(fullAddress);
  };

  const updateFormattedAddress = () => {

    const address1 = !isNil(getValues('street_address')) && !isEmpty(getValues('street_address')) ? `${getValues('street_address')}, ` : '';
    const address2 = !isNil(getValues('street_address_2')) && !isEmpty(getValues('street_address_2')) ? `${getValues('street_address_2')}, ` : '';

    const formattedAddress =  `${address1}${address2}${getValues('city')}, ${getValues('state')}, ${getValues('zip')}, ${getValues('country')}`;
    setValue('formatted_address', formattedAddress);
  }

  const handleStreetAddress = value => {
    setValue('street_address', value);

    if(!isEmpty(value)){
      setLockReset(false);
    }else{
      setLockReset(true);
    }

    updateFormattedAddress();

    return;
  }

  const handleStreetAddressTwo = value => {
    setValue('street_address_2', value);

    if(!isEmpty(value)){
      updateFormattedAddress();
    }

    return;
  }

  const handleCity = value => {
    setValue('city', value);

    if(!isEmpty(value)){
      setLockReset(false);
      updateFormattedAddress();
    }else{
      setLockReset(true);
    }

    return;
  }

  const handleState = value => {
    setValue('state', value);

    if(!isEmpty(value)){
      updateFormattedAddress();
    }else{
      setLockReset(true);
    }

    return;
  }

  const handleZip = value => {
    setValue('zip', value);

    if(!isEmpty(value)){
      setLockReset(false);
      updateFormattedAddress();
    }else{
      setLockReset(true);
    }

    return;
  }

  const handleCountry = value => {
    setValue('country', value);

    if(!isEmpty(value)){
      setLockReset(false);
      updateFormattedAddress();
    }else{
      setLockReset(true);
    }

    return;
  }

  const handleReset = () => {

    
    if(lockReset){
      // document.querySelector('.react-select__clear-indicator').click();
      setValue('formatted_address', null);
      setValue('street_address', null);
      setValue('street_address_2', null);
      setValue('city', null);
      setValue('state', null);
      setValue('zip', null);
      setValue('country', null);
      
      setTimeout(() => {
        document.getElementById('nextBtn').click();
      }, 500);
    }

  }

  useEffect(() => {
    setValue('address_manual_mode', isAddressManual);
  }, [isAddressManual]);

  return(
    <div>
      <Controller
          name="formatted_address"
          control={control}
          rules={{ required: !isAddressManual && 'Address is required' }}
          render={({ field, fieldState }) => {
            const { value, onChange } = field;

            return (
              <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Address
                </label>
                <AddressLookupApi
                  onChange={handleChange(onChange)}
                  onChangeMode={handleChangeMode}
                  manualMode={isAddressManual}
                  value={value}
                  placeholder="Write your address..."
                >
                  <Grid item xs={12}>
                    <Controller
                      name="street_address"
                      control={control}
                      rules={{ required: 'Address is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Address"
                          variant="standard"
                          onChange={event => {
                            handleStreetAddress(event.target.value);
                          }}
                          placeholder="Street Address or P.O. Box"
                          helperText={
                            fieldState.error && lockReset && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="street_address_2"
                      control={control}
                      // rules={{ required: 'Address 2 is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          onChange={event => {
                            handleStreetAddressTwo(event.target.value);
                          }}
                          label="Address 2"
                          variant="standard"
                          placeholder="Apt, Suite, Unit Building (optional)"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} className="d-flex">
                    <Controller
                      name="city"
                      control={control}
                      rules={{ required: 'City is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          onChange={event => {
                            handleCity(event.target.value);
                          }}
                          label="City"
                          variant="standard"
                          helperText={
                            fieldState.error && lockReset && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: 'State is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          onChange={event => {
                            handleState(event.target.value);
                          }}
                          label="State"
                          variant="standard"
                          helperText={
                            fieldState.error && lockReset && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name="zip"
                      control={control}
                      rules={{ required: 'Zip is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          onChange={event => {
                            handleZip(event.target.value);
                          }}
                          label="Zip"
                          variant="standard"
                          helperText={
                            fieldState.error && lockReset && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: 'Country is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          onChange={event => {
                            handleCountry(event.target.value);
                          }}
                          label="Country"
                          variant="standard"
                          helperText={
                            fieldState.error && lockReset && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                </AddressLookupApi>
                <FormHelperText>
                  {fieldState.error && (
                    <Typography variant="p" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )}
                </FormHelperText>
              </div>
            );
          }}
        />
    </div>
  );

}

AddressLookup.defaultProps = {
  AddressData: noop,
  cache: {}
}

AddressLookup.propTypes = {
  AddressData: PropTypes.func,
  cache: PropTypes.object
}

export default AddressLookup;