// Packages
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { NotesList } from '@picklejarapp/notes';

// Relatives
import Layout from '../../layouts/Layout';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';

const NotesIndex = () => {
  const { apiHost } = useContext(AppContext);
  const { token, userNGOSelected } = useContext(UserContext);
  const userDetails = {
    token: token.accessToken,
    tokenType: token.tokenType
  };

  return (
    <Layout title="Manage Notes" breadcrumb={['Home', 'Manage Notes']}>
      <div className="notes-index d-flex flex-wrap justify-content-center">
        <div className="w-100">
          <h3>Manage Notes</h3>
        </div>
        <NotesList apiHost={apiHost} userDetails={userDetails} artistId={userNGOSelected.virtual_artist_id} />
      </div>
    </Layout>
  );
};

NotesIndex.defaultProps = {
  showCancel: false,
  CancelUrl: '/'
};

NotesIndex.propTypes = {
  showCancel: PropTypes.bool,
  CancelUrl: PropTypes.string
};

export default NotesIndex;
