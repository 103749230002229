/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
// Packages
import React, { useState, useContext, useEffect } from 'react';
import { isNil, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { AssignNotesList } from '@picklejarapp/notes';

// Relatives
import AppContext from '../../../contexts/AppContext';
import UserContext from '../../../contexts/UserContext';

const FundraiserStep5 = props => {
  const { cache } = props;
  const { apiHost } = useContext(AppContext);
  const { setValue } = useFormContext();
  const { token, userNGOSelected } = useContext(UserContext);
  const [selectedListIds, setSelectedListIds] = useState([]);

  const userDetails = {
    token: token.accessToken,
    tokenType: token.tokenType
  };

  const loadDefaulCheckboxes = () => {
    const array = [];
    if (!isNil(cache.event_notes) && !isEmpty(cache.event_notes)) {
      cache.event_notes.map((item, index) => {
        setValue(`notes_list_ids[${index}]`, item.id);
        array.push(item.id);
      });
    }
    setSelectedListIds(array);
  };

  const getSelectedList = values => {
    values.map((item, index) => {
      setValue(`notes_list_ids[${index}]`, item);
    });
  };

  useEffect(() => {
    loadDefaulCheckboxes();
  }, []);

  return (
    <AssignNotesList
      apiHost={apiHost}
      userDetails={userDetails}
      artistId={userNGOSelected.virtual_artist_id}
      onSelectData={getSelectedList}
      selectedListIds={selectedListIds}
    />
  );
};

FundraiserStep5.defaultProps = {
  cache: { event_notes: {} }
};

FundraiserStep5.propTypes = {
  cache: PropTypes.object
};

export default FundraiserStep5;
