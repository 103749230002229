// Packages
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';

// Relatives
import Header from './Header';
import Footer from './Footer';
import UserContext from '../../contexts/UserContext';
// import LayoutContext from '../../contexts/LayoutContext';

const Layout = props => {
  const { children, noPadding, className, pageClassName, showHeader,
     showFooter, title, breadcrumb, specialLayout } = props;
  const user = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState(() => get(location, 'state.alerts', []));
  const { isLogged } = user;
  // const { path, flash, breadcrumb, title } = useContext(LayoutContext);

  const handleClickRemoveAlert = position => () => {
    setAlerts(state => {
      const newState = [...state];
      newState.splice(position, 1);

      return newState;
    });
  };

  useEffect(() => {
    navigate('.', { state: { ...location.state, alerts } });
  }, [alerts]);

  return (
    <div className={classNames('layout-wrapper', { [className]: className })}>
      <Helmet>
        <title>PickleJar - Associations Portal - {title}</title>
      </Helmet>
      <div className="layout-bg" />
      {showHeader && <Header specialHeader={specialLayout} isLogged={isLogged} user={user} />}
      <div className="body-wrapper">
        {breadcrumb && breadcrumb.length > 0 && (
          <div className="wrapper__breadcrumb container">
            {breadcrumb.map((item, i) => (
              <div key={i} className="breadcrumb__item">
                {item}
              </div>
            ))}
          </div>
        )}
        <div className="content-wrapper">
          {alerts.length > 0 && (
            <div className="layout-alerts container">
              {alerts.map((alert, i) => (
                <div key={i} className="layout-alert">
                  <div className="alert__message">{alert}</div>
                  <div className="alert__close" onClick={handleClickRemoveAlert(i)}>
                    x
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className={classNames('page container-fluid p-0', { 'p-0': noPadding, [pageClassName]: pageClassName })}>{children}</div>
        </div>
      </div>
      {showFooter && <Footer isLogged={isLogged} user={user} />}
    </div>
  );
};

Layout.defaultProps = {
  className: '',
  pageClassName: '',
  title: '',
  children: null,
  noPadding: false,
  showHeader: true,
  showFooter: true,
  specialLayout: false,
  breadcrumb: []
};

Layout.propTypes = {
  className: PropTypes.string,
  pageClassName: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  noPadding: PropTypes.bool,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  specialLayout: PropTypes.bool,
  breadcrumb: PropTypes.array
};

export default Layout;
