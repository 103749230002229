import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import get from 'lodash/get';
import axios from 'axios';
// import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import omit from 'lodash/omit';
import classNames from 'classnames';

// Relatives
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import http from '../../services/api/http';

const AddressLookupApi = props => {
  const { manualMode: manualModeProp, placeholder, onChange, onChangeMode, value, children /* , required */ } = props;

  let cancelToken;

  const [manualMode, setManualMode] = useState(manualModeProp);
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setManualMode(!manualMode);
    onChangeMode(!manualMode);
  };

  const handleChange = selectedOption => {
    if (!selectedOption) {
      onChange(null);

      return;
    }

    const { value, optionals } = selectedOption;
    if (optionals) {
      onChange({ fullAddress: value, ...optionals });

      return;
    }

    onChange({ fullAddress: value });
  };

  const promiseOptions = inputValue =>
    new Promise(async resolve => {
      if (!inputValue) {
        resolve([]);

        return;
      }



      try  {

        if (typeof cancelToken !== typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.")
        }

        cancelToken = axios.CancelToken.source();

        setLoading(true);
        const response = await http(apiHost, token.accessToken, token.tokenType)
        .get(`/api/web/geocode/default/autocomplete?q=${inputValue}&lookup=0`, {
          cancelToken: cancelToken.token
        })
        .catch(() => {});

        setLoading(false);
        const { success, output } = get(response, 'data', { success: false, data: [] });
        if (!success) {
          resolve([]);

          return;
        }

        const result = output.reduce(
          (acum, address) => [
            ...acum,
            {
              value: get(address, 'description'),
              label: get(address, 'description'),
              optionals: omit(address, ['description'])
            }
          ],
          []
        );

        resolve(result);

      } catch (error) {
        console.log(error);
      }

      
    });

  const formatCreateLabelMemo = useCallback(text => text, []);

  const valueMemo = useMemo(() => {
    if (!value) {
      return null;
    }

    return { value, label: value };
  }, [value]);

  return (
    <div className={classNames('address-lookup', { 'lookup--model-manual': manualMode })}>
      {!manualMode && (
        <AsyncSelect
          className="address-lookup__search-input input--autocomplete"
          classNamePrefix="react-select"
          value={valueMemo}
          onChange={handleChange}
          placeholder={placeholder}
          isClearable
          isSearchable
          loadOptions={promiseOptions}
          defaultOptions
          components={{ DropdownIndicator: null }}
          formatCreateLabel={formatCreateLabelMemo}
        />
      )}
      <button id='manualModeBtn' type="button" className="btn btn-primary" onClick={handleClick} disabled={loading}>
        {manualMode ? 'Lookup Mode' : 'Manual Mode'}
      </button>
      {manualMode && <div className="address-lookup__manual-mode">{children}</div>}
    </div>
  );
};

AddressLookupApi.defaultProps = {
  children: null,
  value: '',
  required: 'streetNumber,streetName,postalCode,city,stateName,stateCode,country,countryCode',
  manualMode: false,
  placeholder: '',
  onChange: noop,
  onChangeMode: noop
};

AddressLookupApi.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  required: PropTypes.string,
  manualMode: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onChangeMode: PropTypes.func
};

export default AddressLookupApi;
