// Packages
import React, { useContext } from 'react';
import get from 'lodash/get';
import { useNavigate } from 'react-router-dom';

// Relatives
import UserContext from '../../contexts/UserContext/UserContext';
import Layout from '../../layouts/Layout';
import UserProfile from '../Login/steps/UserProfile';

const ProfileUpdate = () => {
  const { id, setUserParams, params } = useContext(UserContext);
  const navigate = useNavigate();
  const ngoName = get(params, 'userName', '');

  const handleSubmit = data => {
    if (!data) {
      return;
    }

    setUserParams({ userName: data.handle });
    navigate('/campaigns');
  };

  const handleCancel = () => {
    navigate('/campaigns');
  };

  return (
    <Layout title={`Update ${ngoName}`} breadcrumb={['Home', `Update ${ngoName}`]}>
      <div className="ngo-update mb-5" key="ngo">
        <UserProfile onSubmit={handleSubmit} onCancel={handleCancel} id={id} updateMode />
      </div>
    </Layout>
  );
};

ProfileUpdate.defaultProps = {};

ProfileUpdate.propTypes = {};

export default ProfileUpdate;
