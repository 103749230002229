import React, { useContext } from 'react';
import Layout from '../../layouts/Layout/Layout';

import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import { Dashboard } from '@picklejarapp/portal-dashboards';

export function DashboardComponent(props) {
  const { apiHost } = useContext(AppContext);
  const { token, userNGOSelected } = useContext(UserContext);
  const userDetails = {
    token: token.accessToken,
    tokenType: token.tokenType
  };
  const path = '/dashboard';
  return (
    <Layout title="Dashboard">
      <div className="d-flex align-items-start">
        <Dashboard
          apiHost={apiHost}
          userDetails={userDetails}
          artistId={userNGOSelected.virtual_artist_id}
          eventType={''}
        />
      </div>
    </Layout>
  );
}
