// Packages
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Checkbox, Container, TextField, Typography, CircularProgress } from '@mui/material';
import noop from 'lodash/noop';
import get from 'lodash/get';
import { useNavigate } from 'react-router';

// Relatives
import ModalTerms from '../modals/ModalTerms';
import ModalPrivacy from '../modals/ModalPrivacy';
import http from '../../../services/api/http';
import AppContext from '../../../contexts/AppContext';
import PhoneInput from '../../../components/PhoneInput/PhoneInput';

const LoginPhone = props => {
  const { onSubmit } = props;
  const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone] = useState('');
  const [country, setCountry] = useState('');
  const [language] = useState(() => navigator.language || navigator.userLanguage);
  const { apiHost } = useContext(AppContext);
  const loginForm = useForm({
    defaultValues: {
      phone_number: '+1',
      termsAndConditions: ''
    }
  });
  const navigate = useNavigate();
  const selectOption = type => navigate(`/${type}`);
  const handleClick = () => {
    selectOption('');
  }

  const openPrivacyPolicyModal = () => setOpenPrivacyPolicy(true);

  const getCurrentLocation = async () => {
    const response = await http('https://ip-api.com')
      .get('/json')
      .catch(() => {});

    if (!response || !response.data) {
      setTimezone('America/New_York');
      setCountry('US');

      return;
    }

    const { timezone, countryCode } = get(response, 'data', {});
    setTimezone(timezone);
    setCountry(countryCode);
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  const handleSubmit = async formData => {
    const { phone_number: phoneNumber } = formData;
    setLoading(true);
    const response = await http(apiHost)
      .post('/api/web/v1/new-login-flow/generate-login-process', {
        phone_number: phoneNumber,
        country,
        timezone,
        language
      })
      .catch(() => {});

    setLoading(false);
    const { success, error, data } = get(response, 'data', {});
    if (!success && error) {
      error.fieldErrors.forEach(({ field, message }) => loginForm.setError(field, { type: 'required', message }));

      return;
    }

    onSubmit({ mobilePhone: phoneNumber, confirmationToken: data.confirmationToken });
  };

  return (
    <Container maxWidth="lg">
      <div className={loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </div>
      <Typography color="primary" variant="h1">
        Welcome Back
      </Typography>
      <Typography>if the number below is correct, just click submit</Typography>
      <Box
        component="form"
        maxWidth="sm"
        sx={{ marginLeft: 'auto', marginRight: 'auto' }}
        onSubmit={loginForm.handleSubmit(handleSubmit)}
        autoComplete="off"
      >
        <Controller
          name="phone_number"
          control={loginForm.control}
          rules={{ required: true }}
          render={({ fieldState, field }) => (
            <TextField
              {...field}
              fullWidth
              label="Phone Number"
              variant="standard"
              placeholder="XXXXXXXXX"
              sx={{ maxWidth: '400px' }}
              InputProps={{
                inputComponent: PhoneInput,
                inputProps: {
                  name: field.name
                }
              }}
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />

        <div className="terms-conditions__container">
          <Controller
            name="termsAndConditions"
            control={loginForm.control}
            rules={{ required: true }}
            placeholder="XXXXXXXXX"
            render={({ field }) => (
              <>
                <Checkbox {...field} color="secondary" className="inline-block" />
              </>
            )}
          />

          <span>
            <Typography component="p" className="inline-block">
              I accept the &nbsp;
              <span className="text-primary pointer" onClick={() => setOpenTermsAndConditions(true)}>
                terms and conditions
              </span>
              &nbsp;and{' '}
              <span className="text-primary pointer" onClick={openPrivacyPolicyModal}>
                privacy policy
              </span>
            </Typography>
          </span>
        </div>

        {loginForm.formState.errors.termsAndConditions?.type === 'required' && (
          <Typography color="error">Terms and conditions is required</Typography>
        )}

        <Button type="submit" variant="outlined" size="large" color="primary" disabled={loading}>
          {loading ? 'Loading...' : 'Submit'}
        </Button>
        <div>
        <span>
            <Typography type='button' component="p" color="primary" disabled={loading} className='m-2'>
              <span onClick={handleClick}>Go back</span>
            </Typography>
          </span>
        </div>
      </Box>
      <ModalTerms openModal={openTermsAndConditions} onClose={setOpenTermsAndConditions} />
      <ModalPrivacy openModal={openPrivacyPolicy} onClose={setOpenPrivacyPolicy} />
    </Container>
  );
};

LoginPhone.defaultProps = {
  openModal: false,
  onSubmit: noop
};

LoginPhone.propTypes = {
  openModal: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default LoginPhone;
