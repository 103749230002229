// Packages
import React, { forwardRef, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { IMaskInput } from 'react-imask';
import omit from 'lodash/omit';
import { Avatar } from '@mui/material';
import get from 'lodash/get';

// Relatives
import ModalCountries from './ModalCountries';
import http from '../../services/api/http';
import AppContext from '../../contexts/AppContext';

const PhoneInput = forwardRef((props, ref) => {
  const { onChange, name, mask, valueMasked, value } = props;
  const { apiHost } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [openCountryCodes, setOpenCountryCodes] = useState(false);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState('');
  const [selectedCountryFlag, setSelectedCountryFlag] = useState('');

  const init = async () => {
    const response = await http(apiHost)
      .get('/api/web/settings/default/get-value?group=ngos_allowed_countries&key=allowed_countries&language=en-US')
      .catch(() => {});

    setLoading(false);
    if (!response || !response.data) {
      return;
    }

    const { success, output } = get(response, 'data', {});
    if (!success) {
      return;
    }

    const countrySelected = output.find(({ calling_code: callingCode }) => value.includes(callingCode));
    if (countrySelected) {
      const { calling_code: callingCode, flag } = countrySelected;
      setCountry(callingCode);
      setSelectedCountryFlag(flag);
    } else {
      setCountry('+1');
      setSelectedCountryFlag(
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAALESURBVHja7Jc/aBNxFMc/l0STtqYtihgkYLOYitjuFuwiUgfBUOgSOqS6CNqmRRqLmyjBBDQ4FLRL/TOokEEhgyC4O7RSB0MHWxEtWLGtrW2Su/s9h8ZeUlF7rV4XHzy+995v+d77vnf3fpqIsJ3mYpvtPwENcAPeMjppJlD0APXHj9/44nZvrhh3d45tsvYuAk9GdwM0nTiRkZmZb3L9+jPbuBUDmjyA1zAUIyMviMXaSaVzDPSfJJ3O0V+JqRz9A1acSufQgC+XrlpvJRXCVua06nNXYz36m0kArwtAKUVPTzvJ5FPifR0kk0/pW4/x6jje10GhoEOhaHmx7OtzP50XQDfWOIbb2lISjz+SqakFicVGN4yx2OhWJQh7AAzDJB7vYHDwEclkF4nExnBo6DGz3Rfs959/F8aHGQDKBBSJxEOuXeuit/cemUz3hhBA6d82NfxSKlkStLZekcnJeTl2LC35/Jwt/CsS6LpJT88d7oycJRod5sH9c0Sjw9z/A4Lw8egp0MptLmI9V8br8prPB8WCJYGuK27fPkPk9E2y2T5ORzJks71EIqtxZC2uznd23kJ8y9Vj9zv7MZKGjlROQSg0JKHQZZmYmJVgMLFhDAYTW5YAIBwMJmR8/JPU1Z2XsTF7OL3nkH0PtMj7g20ChDUgHAhczC8tlTAM03ZD52ue258CjwfNX8eBty+bNSBsmmbe5XL2z6yUwu12N3sApve34jFMpKQ7swPs3IGxw2NNgTINRARRpv1tQtbFld3+q3VT3CjTsAgE34/j8/kclWBlZQVqa1cJTO89TI3XiyyvOCNBbQ3LpaK1E5pKVX/B/jkDDaWkQoKPr2hoaHBUgoWFBWhsXCXwLtBCY73fUQJzXxfXKmDqfpPPMu8oAfEDBUwN2AccAfY6vJbPAq+18p3AX0YnrQgsav8vp9tN4PsALYQJa7MTgzkAAAAASUVORK5CYII='
      );
    }

    setCountries(output);
  };

  useEffect(() => {
    setLoading(true);
    init();
  }, []);

  const openCountryCodesModal = () => setOpenCountryCodes(true);

  const selectCountry = (country, flag) => {
    setCountry(() => {
      onChange({ target: { name, value: '' } });

      return country;
    });
    setOpenCountryCodes(false);
    setSelectedCountryFlag(flag);
  };

  if (loading) {
    return <div className="phone-input" />;
  }

  return (
    <div className="phone-input">
      <Avatar onClick={openCountryCodesModal} alt="countryFlag" src={selectedCountryFlag} variant="square" />
      <IMaskInput
        {...omit(props, ['onChange', 'mask', 'valueMasked'])}
        mask={`${country}${mask}`}
        autoComplete="off"
        inputRef={ref}
        onAccept={(val, mask) => {
          if (mask.unmaskedValue === '') {
            onChange({ target: { name, value: country } });

            return;
          }

          onChange({ target: { name, value: valueMasked ? val : mask.unmaskedValue } });
        }}
        overwrite
      />
      <ModalCountries
        className="phone-input__modal"
        countries={countries}
        openModal={openCountryCodes}
        onClose={setOpenCountryCodes}
        onSelectCountry={selectCountry}
      />
    </div>
  );
});

PhoneInput.defaultProps = {
  value: '',
  name: '',
  mask: '0000000000000000000',
  valueMasked: true,
  onChange: noop
};

PhoneInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  mask: PropTypes.string,
  valueMasked: PropTypes.bool,
  onChange: PropTypes.func
};

export default PhoneInput;
