// Packages
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

// Relatives
import ActiveShape from './ActiveShape';

const PieChartCustom = props => {
  const { data, colors, width, height, cx, cy, chartHeight, chartWidth, innerRadius, outerRadius } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  return (
    <ResponsiveContainer width={width} height={height}>
      <PieChart width={chartWidth} height={chartHeight}>
        <Pie
          activeIndex={activeIndex}
          activeShape={ActiveShape}
          data={data}
          cx={cx}
          cy={cy}
          startAngle={180}
          endAngle={0}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Legend verticalAlign="top" align="left" height="auto" />
      </PieChart>
    </ResponsiveContainer>
  );
};

PieChartCustom.defaultProps = {
  height: '100%',
  width: '100%',
  chartHeight: '200',
  chartWidth: '200',
  outerRadius: '60',
  innerRadius: '80',
  cx: '50%',
  cy: '50%',
  data: [],
  colors: []
};

PieChartCustom.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chartHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chartWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  outerRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  innerRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cx: PropTypes.string,
  cy: PropTypes.string,
  data: PropTypes.array,
  colors: PropTypes.array
};

export default PieChartCustom;
