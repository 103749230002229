// Packages
import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormHelperText, Grid, TextareaAutosize, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Relatives
import BasicPopover from '../../../components/BasicPopover';
import UserContext from '../../../contexts/UserContext';

const CampaignStep1 = () => {
  const { control } = useFormContext();
  const { userNGOSelected } = useContext(UserContext);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/fundraisers/create');
  };

  return (
    <>
      <Typography className="inline-block text-muted">
        <span className="mb-4">
          Campaign are meant to run for a multi-Day period of time with a specific “Funding Goal.”
        </span>
        <span className="mb-4">
          If you want to create a single day event, please{' '}
          <a className="text-primary pointer" onClick={handleClick}>
            click here
          </a>{' '}
          to create a Fundraiser.
        </span>
      </Typography>
      <Grid item xs={12}>
        <Controller
          name="ngoname"
          render={() => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <div className="d-flex flex-row bd-highlight">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Organization Name
                </label>
              </div>
              <TextField
                variant="standard"
                defaultValue={userNGOSelected.name}
                InputProps={{
                  readOnly: true
                }}
              />
            </div>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="name"
          control={control}
          rules={{ required: 'Name is required' }}
          render={({ field, fieldState }) => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <div className="d-flex flex-row bd-highlight">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Campaign name
                </label>
                <BasicPopover message="This is what the public will see." />
              </div>
              <TextField
                {...field}
                fullWidth
                // label="Campaign Name"
                variant="standard"
                helperText={
                  fieldState.error && (
                    <Typography variant="p" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )
                }
              />
            </div>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="message"
          control={control}
          rules={{ required: 'Message is required' }}
          render={({ field, fieldState }) => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <div className="d-flex flex-row bd-highlight">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Why are you Raising Money?
                </label>
                <BasicPopover message="Tell people about your organization, mission, and campaign goal." />
              </div>
              <TextareaAutosize {...field} maxLength={255} minRows={3} maxRows={6} label="Mission" variant="standard" />
              <FormHelperText>
                {fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </div>
          )}
        />
      </Grid>
    </>
  );
};

CampaignStep1.defaultProps = {};

CampaignStep1.propTypes = {};

export default CampaignStep1;
