// Packages
import React from 'react';
import { Box } from '@mui/material';

// Relatives
import Layout from '../../layouts/Layout';

const DashboardIndex = () => {
  return (
    <Layout>
      <Box className="dashboard-index" />
    </Layout>
  );
};

export default DashboardIndex;
