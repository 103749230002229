/* eslint-disable camelcase */
/* eslint-disable operator-linebreak */
// Packages
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Typography, Stepper, Step, StepButton } from '@mui/material';
import moment from 'moment';
import { omit, get, isNil, isEmpty } from 'lodash';
import { useNavigate, useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Relatives
import Layout from '../../layouts/Layout/Layout';
import http from '../../services/api/http';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import CampaignStep1 from './createSteps/CampaignStep1';
import CampaignStep2 from './createSteps/CampaignStep2';
import CampaignStep3 from './createSteps/CampaignStep3';
import CampaignStep4 from './createSteps/CampaignStep4';
import CampaignStep5 from './createSteps/CampaignStep5';

const CampaignCreate = props => {
  const { updateMode } = props;
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [loading, setLoading] = useState(updateMode);
  const [stepLoading, setStepLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [cache, setCache] = useState({});
  const { apiHost } = useContext(AppContext);
  const { token, userNGOSelected } = useContext(UserContext);
  const navigate = useNavigate();

  const campaignForm = useForm({
    defaultValues: {
      // step 1
      name: '',
      message: '',
      // step 2
      country: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state: '',
      zip: '',
      date_from: '',
      date_to: '',
      // step 3
      target_goal: '1000',
      // step 4
      media_id: '',
      banner_id: '',
      // step 5
      notes_list_ids: ''
    }
  });

  const steps = [
    {
      label: 'Campaign Details',
      content: <CampaignStep1 />
    },
    {
      label: 'Date And Time',
      content: <CampaignStep2 updateMode={updateMode} />
    },
    {
      label: 'Set your Campaign Goal',
      content: <CampaignStep3 />
    },
    {
      label: 'Add a cover photo',
      content: (
        <CampaignStep4
          setStepLoading={setStepLoading}
          cache={cache}
          setCache={setCache}
          setUploadLoading={setUploadLoading}
        />
      )
    },
    {
      label: 'Set your notes',
      content: <CampaignStep5 cache={cache} />
    }
  ];

  const handleStep = step => async () => {
    if (await campaignForm.trigger()) {
      setActiveStep(step);
    }
  };

  const handleBack = () => setActiveStep(state => state - 1);

  const handleNext = async () => {
    if (await campaignForm.trigger()) {
      setActiveStep(state => state + 1);
    }
  };

  const stepperSubmit = data => {
    if (activeStep !== steps.length - 1) {
      setCompleted(state => ({ ...state, [activeStep]: true }));
      setActiveStep(activeStep + 1);
    } else {
      handleSubmit(data);
    }
  };

  const handleSubmit = async formData => {
    if (!formData || !token) {
      return;
    }

    const NGOResponse = await http(apiHost, token.accessToken, token.tokenType).get(
      `/api/web/v2/non-profit-ngos/${userNGOSelected.id}`
    );

    const { success, output } = get(NGOResponse, 'data', {});
    if (!success) {
      return;
    }

    const { country, street_address: streetAddress, street_address_2: streetAddress2, city, state, zip } = output;
    let notesListIds = String(formData.notes_list_ids);

    if (!isNil(formData.notes_list_ids) && !isEmpty(formData.notes_list_ids) && formData.notes_list_ids.lenght > 1) {
      notesListIds = formData.notes_list_ids.join(',');
    }

    if (isNil(formData.notes_list_ids)) {
      notesListIds = String(0);
    }

    formData = {
      ...formData,
      country,
      notes_list_ids: notesListIds,
      street_address: streetAddress,
      street_address_2: streetAddress2,
      city,
      state,
      zip
    };
    // Admin rules of events
    const userAdmins = get(userNGOSelected, '_misc.non_profit_ngo_members_except_me');
    if (!isNil(userAdmins) && !isEmpty(userAdmins)) {
      formData.additional_event_admins = userAdmins;
    }
    // Admin rules of events
    setLoading(true);
    const { date_from: dateFrom, date_to: dateTo } = formData;
    const timeStart = moment(dateFrom).utc().valueOf() / 1000;
    const timeEnd = moment(dateTo).utc().valueOf() / 1000;
    let response;
    let datesStartEnd;
    if (Number.isNaN(parseFloat(timeStart)) || Number.isNaN(parseFloat(timeEnd))) {
      datesStartEnd = [];
    } else {
      datesStartEnd = [`${timeStart}, ${timeEnd}`];
    }
    if (!updateMode) {
      response = await http(apiHost, token.accessToken, token.tokenType)
        .post('/api/web/v1/events/new-event', {
          ...omit(formData, ['date_from', 'date_to']),
          datesStartEnd,
          artist_ids: [userNGOSelected.virtual_artist_id],
          event_type: 'association-campaign',
          status: 1 // 1 = publish, 4 = draft
        })
        .catch(() => {});
    } else if (updateMode && id) {
      response = await http(apiHost, token.accessToken, token.tokenType)
        .post('/api/web/v1/events/update-event', {
          ...omit(formData, ['date_from', 'date_to', 'full_address']),
          id,
          hour_date_start: timeStart,
          hour_date_end: timeEnd,
          artist_ids: [userNGOSelected.virtual_artist_id],
          event_type: 'association-campaign',
          status: 1 // 1 = publish, 4 = draft
        })
        .catch(() => {});
    } else {
      console.log('Something is wrong');
    }

    setLoading(false);
    if (!response || !response.data) {
      return;
    }

    const { success: success2, error, data } = get(response, 'data', {});
    if (!success2 && error) {
      error.fieldErrors.forEach(({ field, message }) =>
        campaignForm.setError(field, { type: 'required', message }, { shouldFocus: true })
      );

      return;
    }

    if (!updateMode) {
      const responseSecondUpdate = await http(apiHost, token.accessToken, token.tokenType).post(
        '/api/web/v1/events/update-event',
        {
          ...omit(formData, ['date_from', 'date_to', 'full_address']),
          id: data.id,
          hour_date_start: timeStart,
          hour_date_end: timeEnd,
          artist_ids: [userNGOSelected.virtual_artist_id],
          event_type: 'association-campaign',
          status: 1 // 1 = publish, 4 = draft
        }
      );

      if (responseSecondUpdate) {
        navigate('/campaigns');
      }
    } else {
      navigate('/campaigns');
    }
  };

  const loadModel = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/events/${id}`)
      .catch(() => {});

    const { success, output, error } = get(response, 'data', {});
    if (!success) {
      console.warn(error);
      setLoading(false);

      return;
    }

    const {
      name,
      message,
      country,
      city,
      state,
      zip,
      street_address: streetAddress,
      street_address_2: streetAddress2,
      formatted_address: fullAddress,
      target_goal: targetGoal,
      media_id: avatarId,
      banner_id: bannerId
    } = output;

    const fromDate = get(output, '_related.event_dates.0.start_date');
    const toDate = get(output, '_related.event_dates.0.end_date');
    const imageOriginal = get(output, '_misc.avatar.original_image');
    const bannerOriginal = get(output, '_misc.banner.original_image');
    const eventNotes = get(output, '_related.event_notes_lists');

    campaignForm.reset({
      // step 1
      name,
      message,
      // step 2
      full_address: fullAddress,
      country,
      street_address: streetAddress,
      street_address_2: streetAddress2,
      city,
      state,
      zip,
      date_from: fromDate ? new Date(moment(fromDate * 1000)) : '',
      date_to: toDate ? new Date(moment(toDate * 1000)) : '',
      // step 3
      target_goal: String(targetGoal),
      // step 4
      media_id: avatarId,
      banner_id: bannerId
    });

    setCache({ media_id: imageOriginal, banner_id: bannerOriginal, event_notes: eventNotes });
    setLoading(false);
  };

  const handleCancel = () => {
    navigate('/campaigns');
  };

  useEffect(() => {
    if (updateMode && id) {
      loadModel();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (uploadLoading) {
      setLoading(true);
    }

    if (!uploadLoading) {
      setLoading(false);
    }
  }, [uploadLoading]);

  return (
    <Layout title="Create Campaign" breadcrumb={['Home', <Link to="/campaigns">Campaigns</Link>, 'Create Campaign']}>
      <div className="campaign-create">
        <div className="d-flex flex-column col-sm-8 align-items-center justify-content-center mx-auto">
          {activeStep > 0 && (
            <Typography color="primary" variant="h5" className="campaign-title-name text-right mx-auto">
              {`Campaign: ${campaignForm.getValues('name')}`}
            </Typography>
          )}
          <Stepper
            nonLinear
            activeStep={activeStep}
            className={`py-4 align-self-stretch ${uploadLoading ? 'v-hidden' : 'show'}`}
          >
            {steps.map(({ label }, index) => (
              <Step key={label} completed={completed[index]} className="hide-sm-label">
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {/* {label} */}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <FormProvider {...campaignForm}>
            <Box
              component="form"
              onSubmit={campaignForm.handleSubmit(stepperSubmit)}
              className="align-self-stretch"
              autoComplete="off"
            >
              <div className="d-flex flex-column align-items-stretch">
                <Typography color="primary" variant="h1" className="text-center mx-auto">
                  {steps[activeStep].label}
                </Typography>
                {steps[activeStep].content}
              </div>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="primary"
                  variant="outlined"
                  className="btn-forms"
                  disabled={activeStep === 0 || uploadLoading}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {activeStep + 1 === steps.length ? (
                  <Button type="submit" variant="outlined" className="btn-forms" disabled={loading || stepLoading}>
                    {updateMode ? 'Update and Publish' : 'Save and Publish'}
                  </Button>
                ) : (
                  <>
                    <Button
                      sx={{ mr: 1 }}
                      color="primary"
                      type="button"
                      variant="outlined"
                      className="btn-forms"
                      disabled={loading || stepLoading}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    {/* <Button
                      sx={{ mr: 1 }}
                      color="primary"
                      type="button"
                      variant="outlined"
                      className="btn-forms"
                      disabled={loading || stepLoading}
                      onClick={handleDraft}
                    >
                      Save as Draft
                    </Button> */}
                    <Button
                      sx={{ mr: 1 }}
                      id="nextBtn"
                      color="primary"
                      type="button"
                      variant="outlined"
                      className="btn-forms"
                      disabled={loading || stepLoading}
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </FormProvider>
        </div>
      </div>
    </Layout>
  );
};

CampaignCreate.defaultProps = {
  updateMode: false
};

CampaignCreate.propTypes = {
  updateMode: PropTypes.bool
};

export default CampaignCreate;
