// Packages
import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import omit from 'lodash/omit';
import noop from 'lodash/noop';

const FundraiserFilter = props => {
  const { className, filters, value: valueProp, addBtnText, removeBtnText, onSearch, disabled } = props;
  const [filterSelected, setFilterSelected] = useState(() => get(Object.keys(filters), '0'));
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(valueProp);

  useLayoutEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleClickAdd = () => {
    if (!inputValue) {
      return;
    }

    setValue(state => ({ ...state, [filterSelected]: inputValue }));
    setFilterSelected(get(Object.keys(filters), '0'));
    setInputValue('');
  };

  const handleClickRemove = key => () => {
    setValue(state => omit(state, key));
  };

  const handleClickSubmit = () => onSearch(value);

  const handleChangeSelect = e => setFilterSelected(e.target.value);

  const handleChangeInput = e => setInputValue(e.target.value);

  return (
    <div className={classNames('fundraiser-filter', { [className]: className })}>
      <div className="fundraiser-filter__selector">
        <input
          className="form-control"
          value={inputValue}
          onChange={handleChangeInput}
          placeholder={get(filters, filterSelected)}
        />
        <select className="form-select" value={filterSelected} onChange={handleChangeSelect}>
          {Object.keys(filters).map((type, i) => (
            <option key={i} value={type}>
              {filters[type]}
            </option>
          ))}
        </select>
        <button type="button" className="item-btn btn btn-primary" onClick={handleClickAdd}>
          {addBtnText}
        </button>
      </div>
      <div className="fundraiser-filter__items">
        {Object.keys(value).map((valueKey, i) => (
          <div key={i} className="fundraiser-filter__item">
            <div className="item__value">
              <div className="fw-bold">{filters[valueKey]}:</div>
              <div>{value[valueKey]}</div>
            </div>
            <div className="item__btn btn" onClick={handleClickRemove(valueKey)}>
              {removeBtnText}
            </div>
          </div>
        ))}
      </div>
      <button
        type="button"
        className="fundraiser-filter__submit btn btn-primary"
        disabled={disabled}
        onClick={handleClickSubmit}
      >
        Search
      </button>
    </div>
  );
};

FundraiserFilter.defaultProps = {
  className: '',
  disabled: false,
  value: {},
  filters: {
    anything: 'Any',
    name: 'Name',
    city: 'City',
    state: 'State',
    country: 'Country',
    zip: 'Zip'
  },
  addBtnText: '+',
  removeBtnText: 'x',
  onSearch: noop
};

FundraiserFilter.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.object,
  filters: PropTypes.object,
  addBtnText: PropTypes.string,
  removeBtnText: PropTypes.string,
  onSearch: PropTypes.func
};

export default FundraiserFilter;
