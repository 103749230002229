// Packages
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Relatives
import UserContext from '../../contexts/UserContext/UserContext';
import Layout from '../../layouts/Layout';
import JoinNGO from '../../components/joinNGO/JoinNGO';
import Organisation from './steps/Organisation';

const NgoFinancialUpdate = () => {
    const { refreshNGOs } = useContext(UserContext);
    const navigate = useNavigate();
  
    const handleSubmit = async data => {
      if (!data) {
        return;
      }
  
      await refreshNGOs();
      navigate('/campaigns');
    };
  
    const handleCancel = () => {
      navigate('/campaigns');
    };

  return (
    <Layout title='Join or Create Association' breadcrumb={['Home', `Join or Create Association`]}>
      <div className="ngo-update" key="ngo">
        <JoinNGO temp={false} renderComponent={<Organisation
          temp={false} onSubmit={handleSubmit} onCancel={handleCancel} />} />
      </div>
    </Layout>
  );
};

NgoFinancialUpdate.defaultProps = {};

NgoFinancialUpdate.propTypes = {};

export default NgoFinancialUpdate;
