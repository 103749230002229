// Packages
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Container, ListItem, TextField, Typography, CircularProgress } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import noop from 'lodash/noop';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { useNavigate } from 'react-router';

// Relatives
import AppContext from '../../../contexts/AppContext';
import http from '../../../services/api/http';

const VerifySms = props => {
  const { mobilePhone, onSubmit, onReset, confirmationToken } = props;
  const verifySmsForm = useForm({});
  const [loading, setLoading] = useState(false);
  const { apiHost } = useContext(AppContext);
  const navigate = useNavigate();
  const selectOption = type => navigate(`/${type}`);
  const handleClick = () => {
    selectOption('');
  }

  const handleSubmit = async formData => {
    if (!formData || !confirmationToken) {
      return;
    }

    const confirmationCode = Object.values(formData).join('');
    const response = await http(apiHost)
      .post('/api/web/v1/new-login-flow/new-login', {
        confirmation_numbers: confirmationCode,
        confirmation_token: confirmationToken
      })
      .catch(() => {});

    if (!response || !response.data) {
      setLoading(false);

      return;
    }

    const { success, data, error } = get(response, 'data', {});
    if (!success && error.fieldErrors.confirmation_numbers) {
      verifySmsForm.setError('verifySms', { type: 'checkCode', message: error.fieldErrors.confirmation_numbers });

      return;
    }

    const { id, token, userName, status, profile } = data;
    onSubmit({ token, userName, id, status, profile: { avatar: profile.avatar } });
  };

  const focusNextInput = e => {
    const { tabIndex, value } = e.target;
    const index = parseInt(tabIndex, 10);
    if (value.length === 1 && index < 6) {
      const nextSibling = document.querySelector(`input[tabindex="${index + 1}"]`);
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }

    if (index === 6) {
      setTimeout(() => verifySmsForm.handleSubmit(handleSubmit)(), 0);
    }
  };

  const verifySmsError = get(verifySmsForm, 'formState.errors.verifySms.message');

  return (
    <div className="VerifySms" key="verify-sms">
      <div className={loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </div>
      <Container maxWidth="sm">
        <Typography color="primary" variant="h1">
          We Want To Make Sure It&apos;s You
        </Typography>
        <Typography>{`Enter the code sent to ${mobilePhone}`}</Typography>
        <Box component="form" onSubmit={verifySmsForm.handleSubmit(handleSubmit)} autoComplete="off">
          <ListItem>
            {Array.from({ length: 6 }).map((_, i) => (
              <Controller
                key={`verifySmsInput${i}`}
                name={`verifySms${i}`}
                control={verifySmsForm.control}
                defaultValue=""
                rules={{
                  required: 'This field is required',
                  validate: {
                    checkCode: () => {
                      const { verifySms } = verifySmsForm.formState.errors;
                      if (verifySms) {
                        verifySmsForm.clearErrors('verifySms');
                      }
                    }
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="number"
                    pattern="[0-9]*"
                    label=""
                    id="submitSmsForm"
                    placeholder="·"
                    variant="standard"
                    onChange={e => {
                      const getFirstOnly = e.target.value.slice(0, 1);
                      focusNextInput(e);
                      field.onChange(getFirstOnly);
                    }}
                    inputProps={{
                      tabIndex: i + 1,
                      maxLength: 1,
                      className: 'text-center'
                    }}
                  />
                )}
              />
            ))}
          </ListItem>
          {verifySmsError && (
            <Typography variant="p" color="error">
              {verifySmsError}
            </Typography>
          )}
          {Object.keys(omit(verifySmsForm.formState.errors, ['verifySms']))?.length ? (
            <Typography variant="p" color="error">
              All fields are required
            </Typography>
          ) : (
            ''
          )}
          <p>
            Fat fingered the wrong number?{' '}
            <span type="reset" className="text-primary pointer" onClick={onReset}>
              Click Here
            </span>
          </p>
          <div className="d-block">
            <Button type="submit" variant="outlined" size="large" color="primary" disabled={loading}>
              Submit
            </Button>
            <div>
              <span>
                <Typography type='button' component="p" color="primary" disabled={loading} className='m-2'>
                    <span onClick={handleClick}>Go back</span>
                </Typography>
             </span>

            </div>
          </div>
        </Box>
      </Container>
    </div>
  );
};

VerifySms.defaultProps = {
  onSubmit: noop,
  onReset: noop,
  mobilePhone: '',
  confirmationToken: ''
};

VerifySms.propTypes = {
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  mobilePhone: PropTypes.string,
  confirmationToken: PropTypes.string
};

export default VerifySms;
