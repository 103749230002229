// Packages
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormHelperText, Grid, TextareaAutosize, TextField, Typography } from '@mui/material';

// Relatives
import MaskedInput from '../../../../components/MaskedInput';

const OrganisationStep1 = () => {
  const { control } = useFormContext();

  return (
    <>
      <Grid item xs={12}>
        <Controller
          name="name"
          control={control}
          rules={{ required: 'Association Name is required' }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="Association Name"
              variant="standard"
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="virtual_artist_handle"
          control={control}
          rules={{ required: 'Handle is required' }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="Handle"
              variant="standard"
              InputProps={{
                inputComponent: MaskedInput,
                inputProps: {
                  name: field.name,
                  mask: '@name',
                  blocks: {
                    name: {
                      mask: /^[0-9a-zA-Z_]{1,25}$/
                    }
                  }
                }
              }}
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="nickname"
          control={control}
          rules={{ required: 'Association Nickname is required' }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="Association Nickname"
              variant="standard"
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="about"
          control={control}
          rules={{ required: 'About is required' }}
          render={({ field, fieldState }) => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                {' '}
                About
              </label>
              <TextareaAutosize {...field} maxLength={140} minRows={3} maxRows={6} label="About" variant="standard" />
              <FormHelperText>
                {fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </div>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="mission"
          control={control}
          rules={{ required: 'Mission is required' }}
          render={({ field, fieldState }) => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                Mission
              </label>
              <TextareaAutosize {...field} maxLength={255} minRows={3} maxRows={6} label="Mission" variant="standard" />
              <FormHelperText>
                {fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </div>
          )}
        />
      </Grid>
    </>
  );
};

OrganisationStep1.defaultProps = {};

OrganisationStep1.propTypes = {};

export default OrganisationStep1;
