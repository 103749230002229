// Packages
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography, FormHelperText } from '@mui/material';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import classNames from 'classnames';

// Relatives
import ImageCropInput from '../../../../components/ImageCropInput';
import AppContext from '../../../../contexts/AppContext';
import UserContext from '../../../../contexts/UserContext';
import http from '../../../../services/api/http';

const logo = '/images/logo.png';
const banner = '/images/EventsDefault-min.png';

const OrganisationStep4 = props => {
  const { setStepLoading, cache, setCache } = props;
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [loadingBanner, setLoadingBanner] = useState(false);
  const { control, setError, setValue, getValues } = useFormContext();
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);

  const handleSubmit = async (callbackLoading, input, file) => {
    if (!token) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    callbackLoading(true);
    const response = await http(apiHost, token.accessToken, token.tokenType, { 'Content-Type': 'multipart/form-data' })
      .post('/api/web/media/manage/upload', formData)
      .catch(() => {});

    callbackLoading(false);
    if (!response || !response.data) {
      return;
    }

    const { success, output } = get(response, 'data', {});
    if (!success && output) {
      Object.keys(output).forEach(field => setError(field, { type: 'required', message: get(output, `${field}.0`) }));

      return;
    }

    setValue(input, get(response, 'data.data.0.id', ''));
    setCache(state => ({ ...state, [input]: get(response, 'data.data.0.original') }));
  };

  useEffect(() => {
    setStepLoading(loadingAvatar || loadingBanner);
  }, [loadingAvatar, loadingBanner]);

  const onUpload = callbackLoading => (name, data) => handleSubmit(callbackLoading, name, data.file);

  let bannerLarge = banner;

  if(getValues('banner_id')){
    bannerLarge = cache.bannerImage;
  }

  if(cache){
    if (cache.banner_id) {
        bannerLarge = cache.banner_id;
      }
  }
  

  let avatarLarge = logo;

  if(getValues('media_id')){
        avatarLarge = cache.avatarImage;
  }
  
  if(cache){
    if (cache.media_id) {
        avatarLarge = cache.media_id;
      }
  }

  return (
    <div className="event__header">
      <div className="header__banner" style={{ backgroundImage: `url(${bannerLarge}), url(${banner})` }}>
        <Controller
          name="banner_id"
          control={control}
          rules={{ required: 'Banner is required' }}
          render={({ field, fieldState }) => (
            <div className="image-crop-input MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <ImageCropInput
                name={field.name}
                onUpload={onUpload(setLoadingBanner)}
                aspect={16 / 9}
                showPreview={false}
              />
              <FormHelperText>
                {fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </div>
          )}
        />
      </div>
      <div className="header__banner-details">
        <div
          className={classNames('event__avatar', { 'default-avatard': !cache.avatarImage || !cache.media_id })}
          style={{ backgroundImage: `url(${avatarLarge}), url(${logo})` }}
        >
        <Controller
          name="media_id"
          control={control}
          rules={{ required: 'Avatar is required' }}
          render={({ field, fieldState }) => (
            <div className="image-crop-input MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <ImageCropInput name={field.name} onUpload={onUpload(setLoadingAvatar)} aspect={1} showPreview={false} />
              <FormHelperText>
                {fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </div>
          )}
        />
        </div>
      </div>
    </div>
  );
};

OrganisationStep4.defaultProps = {
  setStepLoading: noop,
  cache: null,
  setCache: noop
};

OrganisationStep4.propTypes = {
  setStepLoading: PropTypes.func,
  cache: PropTypes.object,
  setCache: PropTypes.func
};

export default OrganisationStep4;
